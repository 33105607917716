
import { property } from '../api';
import { boundsToTiles, distance } from './tile';
import { getProvinceInfo } from '../utility';

const tile_cache = {};

export const getPropertiesInside = async ({ lowerBounds, upperBounds }) => {
  let properties = [];
  const south = lowerBounds.lat;
  const west = lowerBounds.lng;
  const north = upperBounds.lat;
  const east = upperBounds.lng;
  const center_lat = (south + north) / 2;
  const center_lng = (west + east) / 2;

  const zoom = 8;
  const tiles = boundsToTiles([west, south, east, north], zoom);

  for (const tile of tiles) {
    const [x, y, z] = tile;
    const key = `${x}-${y}-${z}`;
    if (tile_cache[key]) {
      properties = properties.concat(tile_cache[key]);
    } else {
      const tile_properties = await property.getProperties_tile(x, y, z);
      tile_cache[key] = tile_properties;
      properties = properties.concat(tile_properties);
    }
  }

  properties = properties
    .filter(p => p.l[1] >= south && p.l[1] <= north && p.l[0] >= west && p.l[0] <= east)
    .map(p => ({
      id: p.i,
      mls_id: p.i,
      address: p.ad,
      status: p.s === 1 ? 'sale' : 'lease',
      latitude: p.l[1],
      longitude: p.l[0],
      name: p.ad,
      sale_price: p.ps,
      lease_price: p.pl,
      property_photo: p.p,
      last_updated: p.u,
      total_area: p.a,
      total_area_uom: p.am,

      floorSpace: p.fs,
      floorSpaceUnits: p.fsu,

      rlp: p.r,
      property_type: p.pt,
      distance: distance(p.l[1], p.l[0], center_lat, center_lng),
      city: p.c,
      province: p.pr ? getProvinceInfo(p.pr)[0] : '',
      postal_code: p.pc,
      price_uom: p.pu,
      leasePrice_uom: p.lu,
      feedId: p.fi,

      agent_id: p.agid,
      agent_person_id: p.agpi,
      agent2_id: p.ag2id,
      agent2_person_id: p.ag2pi

    }));

  return properties.sort((a, b) => a.distance - b.distance);
};
