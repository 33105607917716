import { app, Component } from 'apprun';
import { uniqByKeepLast, $ } from './utility';
import HomePorperty from './template/home-property-template';
import { getGeoCode, getPropertiesInside_cosmos } from './model/property';
import HomeInquiryForm from './homeInquiryForm';

import { __, lang } from './Txlat';

class homeListing extends Component {
  state = async () => {
    window.addEventListener('keyup', function (event) {
      if (event.key === 'Enter') {
        const searchB = document.getElementsByClassName('houzez-search-button')[0] as HTMLInputElement;
        searchB.click();
      }
    });

    // await getGeoLocation();
    const max_cards = 6;
    const gcode = await getGeoCode('');
    const localcode = gcode.find(g => g.types.includes('locality'));
    const bounds = (localcode || gcode[0]).geometry.viewport;
    const lowerBounds = bounds.southwest;
    const upperBounds = bounds.northeast;
    const properties = await getPropertiesInside_cosmos(
      {
        lowerBounds, upperBounds
      });
    //const properties = await getPropertiesNearby_cosmos('', 'true');
    const rlpProperties = properties.filter(p => p.rlp === 'true');
    const sortProperty = rlpProperties.sort(function (a, b) { return a.distance - b.distance; });
    const upf = uniqByKeepLast(sortProperty, it => it.name);
    // console.log(upf);
    const res = upf.slice(0, max_cards);

    return res;
  };

  view = (state) => {

    return (
      <div style='text-align:center;'>
        {state.map(p => <HomePorperty property={p} />)}
        <div><a href={lang.get() === 'fr' ? '/fr/proprietes/' : '/properties/'} class="btn houzez-search-button btn-search btn-secondary">{__('See more local listings')}</a></div>
      </div>
    );
  };
}

const element = $('.listing-view.grid-view') as HTMLElement;
element && new homeListing().start(element);
new HomeInquiryForm().start('.elementor-form');
