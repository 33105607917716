const minLatitude = -85.05; //112878; // The minimum latitude in degrees
const maxLatitude = 85.05; //112878; // The maximum latitude in degrees
const minLongitude = -180; // The minimum longitude in degrees
const maxLongitude = 179.9999; // The maximum longitude in degrees

function clip(value, minValue, maxValue) {
  return Math.min(Math.max(value, minValue), maxValue);
}

function radToDeg(rad) {
  return rad * 180 / Math.PI;
}

export const latLngToTileXY = (lat, lng, zoom) => {
  lat = clip(lat, minLatitude, maxLatitude);
  lng = clip(lng, minLongitude, maxLongitude);
  const x = (lng + 180) / 360 * (1 << zoom);
  const latRad = lat * Math.PI / 180;
  const y = (1 - Math.log(Math.tan(latRad) + 1 / Math.cos(latRad)) / Math.PI) / 2 * (1 << zoom);
  return [Math.floor(x), Math.floor(y)];
};

export const tileToBounds = (x, y, z) => {
  x = parseInt(x);
  y = parseInt(y);
  z = parseInt(z);
  const north = radToDeg(Math.atan(Math.sinh(Math.PI * (1 - 2 * y / Math.pow(2, z)))));
  const west = x / Math.pow(2, z) * 360 - 180;
  const south = radToDeg(Math.atan(Math.sinh(Math.PI * (1 - 2 * (y + 1) / Math.pow(2, z)))));
  const east = (x + 1) / Math.pow(2, z) * 360 - 180;
  return [west, south, east, north];
};

export const boundsToTiles = (bounds, zoom) => {
  const [west, south, east, north] = bounds;
  const [x1, y1] = latLngToTileXY(south, west, zoom);
  const [x2, y2] = latLngToTileXY(north, east, zoom);
  const minX = Math.min(x1, x2);
  const maxX = Math.max(x1, x2);
  const minY = Math.min(y1, y2);
  const maxY = Math.max(y1, y2);
  const tiles = [];
  for (let x = minX; x <= maxX; x++) {
    for (let y = minY; y <= maxY; y++) {
      tiles.push([x, y, zoom]);
    }
  }
  return tiles;
};

export function distance(lat1, lon1, lat2, lon2, unit = 'K') {
  if ((lat1 === lat2) && (lon1 === lon2)) {
    return 0;
  } else {
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') { dist = dist * 1.609344; }
    if (unit === 'N') { dist = dist * 0.8684; }
    return dist;
  }
}
