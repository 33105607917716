const defaultBasePath = window['defaultBasePath'] || '/api';

const access_token = '';

async function fetchAsync(method: 'GET' | 'POST' | 'DELETE' | 'PUT', url: string, body?: any, signal?: AbortSignal) {
  const headers = access_token ? { Authorization: `Bearer ${access_token}` } : {};
  headers['Content-Type'] = 'application/json; charset=utf-8';
  url = `${defaultBasePath}${url}`;
  const response = await fetch(url, {
    method,
    headers,
    signal,
    body: body && JSON.stringify(body)
  });

  if (!response.ok) {
    throw response.statusText;
  }

  return response.json();
}

export async function get<T = any>(url: string, signal?): Promise<T> {
  //const ok = !auth || await check_jwt();
  return fetchAsync('GET', url, null, signal);
}

export async function post<T = any>(url: string, body: any, signal?): Promise<T> {
  //const ok = !auth || await check_jwt();
  return fetchAsync('POST', url, body, signal);
}
