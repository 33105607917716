import { property, agent, geoCode } from '../api';
import { lang, __ } from '../Txlat';
import { getGeoLocation, formatterCurrency } from '../utility';

import { getPropertiesInside } from './property_tile';
export const getPropertiesInside_cosmos = getPropertiesInside;
export const getPropertyByID = property.getPropertyByID_cosmos;
export const getDeletedPropertyByID = property.getDeletedPropertyByID;
export const getSimilarListing = property.getSimilarListing;

// export const getPropertiesInside_cosmos = property.getPropertiesInside_cosmos;
export const getPropertyAgentByID = agent.getAgentByID;

export const getCommercialAgents = agent.getCommercialAgents;

export async function getGeoCode(keyword) {
  if (!keyword) {
    const { GeoLocation_Longitude, GeoLocation_Latitude } = await getGeoLocation();
    keyword = `{"lat":${GeoLocation_Latitude}, "lng":${GeoLocation_Longitude}}`;
  }
  return geoCode.getGeoCode(keyword);
}

export async function getPropertiesNearby_cosmos(keyword, rlp) {
  const { GeoLocation_Longitude, GeoLocation_Latitude } = await getGeoLocation();
  return property.getPropertiesNearBy_cosmos(GeoLocation_Longitude, GeoLocation_Latitude, keyword, rlp);
}

export function getPropertyMetaDescription(property) {
  return lang.get() === 'fr' ? property.description_fr : property.description;
}

export function getPropertyFeatures(property) {
  let features = [];
  if (lang.get() === 'fr') {
    if (property.propFeatures) {
      features = property.propFeatures.map(f => ({ 'key': f.feature[1], 'value': f.values ? f.values[1].join(', ') : '' }));
    }

    if (property.buildingFeatures) {
      for (let i = 0; i < property.buildingFeatures.length; i++) {
        const f = property.buildingFeatures[i];
        features.push({ 'key': f.feature[1], 'value': f.values ? f.values[1].join(', ') : '' });
      }
    }
  } else {
    if (property.propFeatures) {
      features = property.propFeatures.map(f => ({ 'key': f.feature[0], 'value': f.values ? f.values[0].join(', ') : '' }));
    }

    if (property.buildingFeatures) {
      for (let i = 0; i < property.buildingFeatures?.length; i++) {
        const f = property.buildingFeatures[i];
        features.push({ 'key': f.feature[0], 'value': f.values ? f.values[0].join(', ') : '' });
      }
    }
  }

  if (property.neighbourhood) {
    features.push({ 'key': __('Neighbourhood'), 'value': property.neighbourhood });
  }

  if (property.parkingSpaces) {
    features.push({ 'key': __('No. of Parking Spaces'), 'value': property.parkingSpaces });
  }

  if (property.zoning) {
    features.push({ 'key': __('Zoning Type'), 'value': property.zoning });
  }

  if (property.lotSize) {
    features.push({ 'key': __('Lot Size'), 'value': property.lotSize + ' ' + (property.lotUOM ?? '') });
  }

  if (property.buildingWidth && property.buildingDepth && property.buildingUnits) {
    features.push({ 'key': __('Size'), 'value': property.buildingWidth + ' X ' + property.buildingDepth + ' ' + property.buildingUnits });
  }

  if (property.lotFrontage) {
    features.push({ 'key': __('Lot Frontage'), 'value': property.lotFrontage + ' ' + (property.lotDimensionUOM ?? '') });
  }

  if (property.lotDepth) {
    features.push({ 'key': __('Lot Depth'), 'value': property.lotDepth + ' ' + (property.lotDimensionUOM ?? '') });
  }

  if (property.floorSpace) {
    features.push({ 'key': __('Floor Space'), 'value': property.floorSpace + ' ' + property.floorSpaceUnits });
  }

  if (property.totalBaths) {
    features.push({ 'key': __('Bathrooms'), 'value': property.totalBaths });
  }

  if (property.taxAmount) {
    features.push({ 'key': __('Annual Tax Amount'), 'value': formatterCurrency(property.taxAmount) });
  }

  if (property.utilityWater) {
    features.push({ 'key': __('Water'), 'value': property.utilityWater });
  }

  // features?.sort((a, b) => a.key.localeCompare(b.key));
  return features;
}
export function getPropertyDetailStatusPrice(property) {
  const formatter = new Intl.NumberFormat(lang.get() === 'fr' ? 'fr-CA' : 'en-CA', {
    style: 'currency',
    currency: 'CAD',
    maximumFractionDigits: 0
  });

  let status = '';
  let price = '';

  switch (property.status) {
    case 'sale':
      status = __('For Sale');
      if (property.price_uom === 'FEETSQ') {
        price = formatter.format(Math.round(property.sale_price)) + __('/Sq.Ft.');
      } else if (property.price_uom === 'ACRE') {
        price = formatter.format(Math.round(property.sale_price)) + __('/Acre');
      } else if (property.price_uom === 'METERSQ') {
        price = formatter.format(Math.round(property.sale_price)) + __('/Sq.m.');
      } else {
        price = formatter.format(Math.round(property.sale_price));
      }
      break;
    case 'lease':
      status = __('For Lease');
      if (!property.leasePrice_uom) {
        price = formatter.format(Math.round(property.lease_price)) + __('/Month');
      } else if (property.leasePrice_uom === 'FEETSQ') {
        price = formatter.format(Math.round(property.lease_price)) + __('/Sq.Ft.');
      } else if (property.leasePrice_uom === 'ACRE') {
        price = formatter.format(Math.round(property.lease_price)) + __('/Acre');
      } else if (property.leasePrice_uom === 'METERSQ') {
        price = formatter.format(Math.round(property.lease_price)) + __('/Sq.m.');
      } else {
        price = formatter.format(Math.round(property.lease_price)) + __('/Month');
      }
      break;

    case 'both':
      status = __('For Sale') + ' / ' + __('For Lease');
      price = formatter.format(Math.round(property.sale_price)) + '   ' + formatter.format(Math.round(property.lease_price)) + __('/Month');
      break;
  }
  return { status, price };
}

export const sortProperties = function (properties, sortBy) {
  switch (sortBy) {
    case 'a_price': {
      properties.sort((p1, p2) => {
        if (p1.sale_price === p2.sale_price) {
          return p1.lease_price - p2.lease_price;
        } else {
          return p1.sale_price - p2.sale_price;
        }
      });
      break;
    }
    case 'd_price': {
      properties.sort((p1, p2) => {
        if (p1.sale_price === p2.sale_price) {
          return p2.lease_price - p1.lease_price;
        } else {
          return p2.sale_price - p1.sale_price;
        }
      });
      break;
    }
    case 'a_date': {
      properties.sort((p1, p2) => {
        return Date.parse(p1.last_updated) - Date.parse(p2.last_updated);
      });
      break;
    }

    case 'd_date': {
      properties.sort((p1, p2) => {
        return Date.parse(p2.last_updated) - Date.parse(p1.last_updated);
      });
      break;
    }

    default: {
      properties.sort((p1, p2) => {
        return p1.distance - p2.distance;
      });
    }
  }
  return properties;
};
