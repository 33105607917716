import dict from './dict.json';

export const DEFAULT_LANGUAGE = 'en';
export const FR_LANGUAGE = 'fr';
export const FR_PAGE_PATH = '/fr/';
export const LANGUAGE_COOKIIE_NAME = 'pll_language';

const getTxlatStr = (element: HTMLElement) => {
  return `"${(element.innerText).replace(':', '":"')}"`;
};

const getTxlateStrs = (txlatTag) => {
  const txlatElements = document.getElementsByTagName(txlatTag);
  //console.log('txlatElements', txlatElements);
  const txlatStrArray = Array.prototype.map.call(txlatElements, (element) => {
    return getTxlatStr(element as HTMLElement);
  });

  return txlatStrArray;
};

const getLocalDict4Lang = (txlatTag) => {
  const txlateStrs = getTxlateStrs(txlatTag);//);
  //console.log('txlateStrs',txlateStrs);
  return JSON.parse(`{ ${txlateStrs.join(',')} }`);
};

const getLocalDict = () => {
  const localDictFr = getLocalDict4Lang('x-translate-fr');
  const localDictEn = getLocalDict4Lang('x-translate-en');
  //console.log({localDictFr,localDictEn});
  return { en: { ...dict.en, ...localDictEn }, fr: { ...dict.fr, ...localDictFr } };
};

const dict2 = getLocalDict();
//console.log('dict',dict2);

export const lang = {
  currentLang: null,
  get: () => {
    if (!lang.currentLang) {
      lang.set(getLangFromURL()); // getCookie(LANGUAGE_COOKIIE_NAME);
    }
    if (!lang.currentLang) {
      lang.set(DEFAULT_LANGUAGE);
    }
    return lang.currentLang;
  },
  set: (value) => {
    lang.currentLang = value;
    document.cookie = `${LANGUAGE_COOKIIE_NAME}=${value}`;
  },
};

export const get_lang = () => {
  return getCookie(LANGUAGE_COOKIIE_NAME) || DEFAULT_LANGUAGE;
};

export const getCookie = function (name) {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  if (match) { return match[2]; }
};

export const getLangFromURL = function () {
  const pathname = window.location.pathname;
  return (pathname.startsWith(FR_PAGE_PATH)) ? FR_LANGUAGE : DEFAULT_LANGUAGE;
};
export const __ = (text) => {
  //console.log('In Txlat.__');
  const l = lang.get();
  const txt = (lang && dict2[l] && dict2[l][text]) || (l === DEFAULT_LANGUAGE ? text : `${text}*`);
  return txt;
};

export const __C = (text) => {
  //console.log('In Txlat.__C');
  const formatter = new Intl.NumberFormat(`${lang.get()}-CA`, {
    style: 'currency',
    currency: 'CAD',
  });

  return formatter.format(text);
};
