import { app, Component } from 'apprun';
import { captcha } from './captcha';
import { __, lang } from './Txlat';

import { getProvinceInfo } from './utility';
declare const jQuery;

export default class HomeInquiryForm extends Component {
  state = {
    gcaptcha: captcha.get()
  };

  view = () => {

    return <>
      <div class="form-group ">
        <label for="inqueryType" class="elementor-field-label">{__('Inquiry Type')}</label>
        <select id='typeDP' name='inqueryType' class="selectpicker form-control " title={__('Inquiry Type')} required data-msgfield={__('Inquiry Type') + ': '} $onchange={['makeMandatory', 'typeDP']}>
          <option value='Purchase'>{__('Purchase')}</option>
          <option value='Sell'>{__('Sell')}</option>
          <option value='Lease' >{__('Lease')}</option>
          <option value='Evaluation' >{__('Evaluation')}</option>
        </select>
        <div id='typeDPError' class="invalid-feedback">
          {__('Please make a selection.')}
        </div>
      </div>

      <div class="form-group ">
        <label for="information" class="elementor-field-label">{__('Information')}</label>
        <select id='informationDP' name='information' class="selectpicker form-control" title={__('Information')} required data-msgfield={__('Information') + ': '} $onchange={['makeMandatory', 'informationDP']}>
          <option value='Estate agent'>{__('I\'m a real estate agent')}</option>
          <option value='Property owner'>{__('I\'m a property owner')}</option>
          <option value='Other'> {__('Other')}</option>
        </select>
        <div id='informationDPError' class="invalid-feedback">
          {__('Please make a selection.')}
        </div>
      </div>

      <div class="form-group">
        <input class="form-control" id='otherinfo' name="otherInfo" value="" type="text" placeholder={__('Other Infomation')} spellcheck="false" style='display:none' data-msgfield="Information (Other Infomation): " />
        <div class="invalid-feedback">
          {__('Please provide your Information')}
        </div>
      </div>

      <div class="form-group">
        <input class="form-control" id="name" name="name" value="" type="text" placeholder={__('Your Name')} spellcheck="false" pattern="^\s*\S(?:.*\S)?\s*$" required data-msgfield={__('Name') + ': '} />
        <div class="invalid-feedback">
          {__('Please provide your name.')}
        </div>
      </div>

      <div class="form-group">
        <input class="form-control" name="email" value="" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder={__('Email Address')} required data-msgfield={__('Email Address') + ': '} />
        <div class="invalid-feedback">
          {__('Please provide a valid email address.')}
        </div>
      </div>

      <div class="form-group ">
        <label for="province" class="elementor-field-label">{__('Province')}</label>

        <select id='provinceDP' name="province" class="selectpicker form-control" required="required" title="Province" data-msgfield={__('Province') + ': '} $onchange={['makeMandatory', 'provinceDP']}>
          <option value='AB'>{__('Alberta')}</option>
          <option value='BC'>{__('British Columbia')}</option>
          <option value='MB'>{__('Manitoba')}</option>
          <option value='NB'>{__('New Brunswick')}</option>
          <option value='NL'>{__('Newfoundland and Labrador')}</option>
          <option value='NT'>{__('Northwest Territories')}</option>
          <option value='NS'>{__('Nova Scotia')}</option>
          <option value='NU'>{__('Nunavut')}</option>
          <option value='ON'>{__('Ontario')}</option>
          <option value='PE'>{__('Prince Edward Island')}</option>
          <option value='QC'>{__('Quebec')}</option>
          <option value='SK'>{__('Saskatchewan')}</option>
          <option value='YT'>{__('Yukon')}</option> </select>
        <div id="provinceDPError" class="invalid-feedback">
          {__('Please make a selection.')}
        </div>
      </div>

      <div class="form-group">
        <label for="province" class="elementor-field-label">{__('City')}</label>
        <input class="form-control" id="city" name="city" value="" type="text" placeholder={__('City')} spellcheck="false" required data-msgfield={__('City') + ': '} />
        <div class="invalid-feedback">
          {__('Please provide city name.')}
        </div>
      </div>

      <div class="form-group ">
        <label for="Property" class="elementor-field-label">{__('Property type')}</label>
        <select id="propertyDP" name="Property" class="selectpicker form-control" required="required" title={__('Property')} data-msgfield={__('Property') + ': '} $onchange={['makeMandatory', 'propertyDP']}>
          <option value='Agricultural'>{__('Agricultural')}</option>
          <option value='Business'>{__('Business')}</option>
          <option value='Hotels/Motels'>{__('Hotels/Motels')}</option>
          <option value='Industrial'>{__('Industrial')}</option>
          <option value='investment'>{__('Investment')}</option>
          <option value='Land'>{__('Land')}</option>
          <option value='Multi Family'>{__('Multi Family')}</option>
          <option value='Retail'>{__('Retail')}</option>
          <option value='Office'>{__('Office')}</option>
          <option value='Other'>{__('Other')}</option>
        </select>
        <div id="propertyDPError" class="invalid-feedback">
          {__('Please make a selection.')}
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <input class="form-control" name="mprice" value="" type="number" placeholder={__('Max Price')} title={__('Max Price')} data-msgfield={__('Max price') + ': '} />
        </div>
        <div class="col">
          <input class="form-control" name="msize" value="" type="number" placeholder={__('Min Size Sq Ft')} title={__('Min Size Sq Ft')} data-msgfield={__('Min Size Sq Ft') + ': '} />
        </div>
      </div>
      <div class="form-group "></div>

      <a class="btn btn-search btn-secondary btn-full-width" href='' style='color:white;' $onclick='onsubmit'>
        {__('Submit')}<span class="btn-loader houzez-loader-js"></span>
      </a>
      <div id='success' class="valid-feedback" style='display:none'>
        {__('Your email has been sent successfully!')}
      </div>
      <div id='failmsg' class="invalid-feedback" style='display:none'>
        {__('Error occured while submitting your email!')}
      </div>
      <div id='failemailmsg' class="invalid-feedback" style='display:none'>
        {__('Hey rlp Agent, please contact the listing agent.')}
      </div>
    </>;
  };

  update = {
    onsubmit: async (state, e) => {
      document.getElementById('success').style.display = 'none';
      document.getElementById('failmsg').style.display = 'none';

      document.getElementById('failemailmsg').style.display = 'none';

      const typeDPselector = document.getElementById('typeDP');
      const typeDPerrorMsg = document.getElementById('typeDPError');

      if (typeDPselector.matches(':invalid')) {
        typeDPerrorMsg.classList.add('d-block');
      } else {
        typeDPerrorMsg.classList.remove('d-block');
      }

      const informationDPselector = document.getElementById('informationDP');
      const informationDPerrorMsg = document.getElementById('informationDPError');

      if (informationDPselector.matches(':invalid')) {
        informationDPerrorMsg.classList.add('d-block');
      } else {
        informationDPerrorMsg.classList.remove('d-block');
      }

      const provinceDPselector = document.getElementById('provinceDP');
      const provinceDPerrorMsg = document.getElementById('provinceDPError');

      if (provinceDPselector.matches(':invalid')) {
        provinceDPerrorMsg.classList.add('d-block');
      } else {
        provinceDPerrorMsg.classList.remove('d-block');
      }

      const propertyDPselector = document.getElementById('propertyDP');
      const propertyDPerrorMsg = document.getElementById('propertyDPError');

      if (propertyDPselector.matches(':invalid')) {
        propertyDPerrorMsg.classList.add('d-block');
      } else {
        propertyDPerrorMsg.classList.remove('d-block');
      }

      const form = e.target.parentElement as HTMLFormElement;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        form.classList.add('was-validated');
      } else {

        const email = (document.querySelector('input[name="email"]') as HTMLInputElement).value;

        if (email.toLocaleLowerCase().includes('royallepage.ca')
          || email.toLocaleLowerCase().includes('johnstonanddaniel.com')
          || email.toLocaleLowerCase().includes('royallepagecommercial.com')) {
          document.getElementById('failemailmsg').style.display = 'block';
          e.preventDefault();
          e.stopPropagation();
          form.classList.remove('was-validated');
          return;
        }

        try {

          const name = (document.querySelector('input[name="name"]') as HTMLInputElement).value;
          const text = '';
          const url = window.location.protocol + '//' + window.location.hostname;

          const inquiryTypeElement = document.getElementById('typeDP') as HTMLSelectElement;
          const inquiryType = inquiryTypeElement.options[inquiryTypeElement.selectedIndex].value;

          const informationDPElement = document.getElementById('informationDP') as HTMLSelectElement;
          const information = informationDPElement.options[informationDPElement.selectedIndex].value;

          const otherInfo = (document.querySelector('input[name="otherInfo"]') as HTMLInputElement).value;

          const provinceCodeElement = document.getElementById('provinceDP') as HTMLSelectElement;
          const provinceCode = provinceCodeElement.options[provinceCodeElement.selectedIndex].value;

          const city = (document.querySelector('input[name="city"]') as HTMLInputElement).value;

          const propertyElement = document.getElementById('propertyDP') as HTMLSelectElement;
          const property = propertyElement.options[propertyElement.selectedIndex].value;

          const mprice = (document.querySelector('input[name="mprice"]') as HTMLInputElement).value;
          const msize = (document.querySelector('input[name="msize"]') as HTMLInputElement).value;

          const listing_content = `inquiry_type: ${inquiryType}
information: ${information}
other_information: ${otherInfo}
search_category: ${property}
max_price: ${mprice}
min_size: ${msize}
listing_type: Commercial`;

          const data = {
            name,
            email,
            fsa: '',
            text,
            lang: lang.get(),
            geolocation_address: '',
            source_email: 'leads@royallepagecommercial.com',
            lead_source: 'COM_DDF_CONNECT',
            listing_content,
            url: url,
            poi_city: city,
            poi_state: provinceCode,
            poi_zip: '',
            province: getProvinceInfo(provinceCode)[1],
            avg_price: '',
            lat: '',
            lng: '',
          };

          const result = await captcha.leadAssign(data);

          if (result && (result !== '')) {
            jQuery('#typeDP').val('').selectpicker('refresh');
            jQuery('#informationDP').val('').selectpicker('refresh');
            jQuery('#provinceDP').val('').selectpicker('refresh');
            jQuery('#propertyDP').val('').selectpicker('refresh');
            (document.getElementsByClassName('elementor-form')[0] as HTMLFormElement).reset();  // Reset all form data
            document.getElementById('success').style.display = 'block';
            form.classList.remove('was-validated');
          }
        } catch (error) {
          // const err = await error.json();
          document.getElementById('failmsg').style.display = 'block';
        }

      }

    },

    makeMandatory: (_, name) => {
      const errorMsg = document.getElementById(`${name}Error`);
      errorMsg.classList.remove('d-block');

      if (name === 'informationDP') {
        const e = document.getElementById(name) as HTMLInputElement;
        const selectedValue = e.value;
        if (selectedValue === __('Other')) {
          document.getElementById('otherinfo').style.display = 'block';
          (document.getElementById('otherinfo') as HTMLInputElement).required = true;
        } else {
          document.getElementById('otherinfo').style.display = 'none';
          (document.getElementById('otherinfo') as HTMLInputElement).required = false;
        }
      }
    },
  };
  rendered = () => {
    jQuery('.selectpicker').selectpicker('refresh');
  };
}
