import {
  get,
  post,
} from './fetch';

let controller: AbortController;
export const property = {

  getPropertyByID_cosmos: async (id) => {
    try {
      const property = await get('/property_cosmos/' + id);
      return property;
    } catch (e) {
      return null;
    }
  },

  getDeletedPropertyByID: async (id) => {
    try {
      const property = await get('/property_deleted_cosmos/' + id);
      return property;
    } catch (e) {
      return null;
    }
  },

  getSimilarListing: async (postcode, property_type) => {
    const req_o = {
      'postcode': postcode,
      'property_type': property_type
    };

    if (controller) { controller.abort(); }
    controller = new AbortController();
    return post('/properties_similar_listing', req_o, controller.signal);
  },

  getPropertiesNearBy_cosmos: (lng, lat, keyword, rlp) => {
    const req_o = {
      lng,
      lat,
      'keyword': keyword,
      rlp
    };
    if (controller) { controller.abort(); }
    controller = new AbortController();
    return post('/properties_nearby_location_cosmos', req_o, controller.signal);
  },

  getPropertiesInside_cosmos: (req_o) => {
    if (controller) { controller.abort(); }
    controller = new AbortController();
    return post('/properties_inside_cosmos', req_o, controller.signal);
  },

  getProperties_tile: async (x, y, z) => get(`/properties_tile/${x}/${y}/${z}`),
};

export const agency = {
  getAgencyByID: (id) => {
    return get('/agency/' + id);
  },
  getAllAgencys: () => {
    return get('/agencies');
  },

  getAllAgencyProperties: (id) => {
    const req_o = {
      'id': id
    };

    return post('/agencies_properties_cosmos', req_o);
  },

  getAllAgencyAgents: (id) => {
    return get('/agents_by_agency_id/' + id);
  }
};

export const agent = {
  getAgentByID: (id) => {
    return get('/agent/' + id);
  },
  getAllAgents: () => {
    return get('/agents');
  },
  getAgentByIDAndPersonID: (id, person_id) => {
    return get('/agent_by_id_and_personid/' + id + '/' + person_id);
  },

  getAllAgentProperties: (id, alaiaId, externalId) => {
    const req_o = {
      'personId': id,
      'alaiaId': alaiaId,
      'externalId': externalId
    };

    return post('/agent_properties_cosmos', req_o);
  },

  getCommercialAgents: () => {
    return get('/agents_commercial');
  }
};

export const email = {
  post: (token, msgType, msgSub, msg, to) => {
    const payload = {
      'token': token,
      'msgType': msgType,
      'msgSub': msgSub,
      'msg': msg,
      'to': to,
    };

    return post('/email', payload);
  },
};

export const leadAssign = {
  post: (data) => {
    return post('/lead_assign', data);
  },
};

export const geoCode = {
  getGeoCode: (keyword) => {
    if (controller) { controller.abort(); }
    controller = new AbortController();
    return get('/geocode/' + keyword, controller.signal);
  }
};
