import app from 'apprun';
import { __, lang } from '../Txlat';
import { getPropertyLink, getEllipsisString, checkNumber } from '../utility';
import { getPropertyDetailStatusPrice } from '../model/property';

export default function ({ property }) {
  const statusPrice = getPropertyDetailStatusPrice(property);

  return (
    <div class="item-listing-wrap hz-item-gallery-js item-listing-wrap-v3 card" data-images="">
      <div class="item-wrap item-wrap-v3 h-100">
        <div class="listing-image-wrap">
          <div class="listing-thumb" >
            <a target={'property' + property.id} href={getPropertyLink(property, lang.get())} class="listing-featured-thumb hover-effect">
              <img width="400" height="300" src={property.property_photo} style="width:400; height: 300px !important; object-fit: cover;object-position:0 0; max-width: 100%; background-color: #CDCDCD; cursor: pointer; " /></a>
          </div>
        </div>

        <div class="labels-wrap labels-right" style="bottom:120px">
          <a href="" class="label-status label status-color-28" style="color:white">
            {statusPrice.status}
          </a>
        </div>

        <h2 class="item-title" style="bottom:95px; white-space: nowrap;">
          <a target={'property' + property.id} href={getPropertyLink(property, lang.get())}>{getEllipsisString(property.address, 40)}</a>
        </h2>

        <ul class="item-price-wrap" style="bottom:70px">
          <li style="white-space: nowrap;">{getEllipsisString(property.city.toUpperCase() + ' ' + property.province.toUpperCase() + ' ' + property.postal_code.toUpperCase(), 40)}</li>
        </ul>

        <ul class="item-amenities item-amenities-with-icons" style="bottom:45px">
          <li class="item-price">{statusPrice.price} &nbsp; <i class="houzez-icon icon-ruler-triangle mr-1"></i><span class="hz-figure">{checkNumber(property.total_area?.split(';')[0])}</span> <span class="area_postfix">{' ' + __('Sq.Ft')}</span></li>
        </ul>

        <ul class="item-amenities item-amenities-with-icons" style="bottom:20px">
          <li class="item-price">{'MLS®' + property.mls_id}</li>
        </ul>

        <div class="preview_loader"></div>
      </div>
    </div>

  );
}
