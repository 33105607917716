import { lang, __ } from './Txlat';

export const RoyalLePage_Latitude = 43.723358;
export const RoyalLePage_Longitude = -79.335350;
export let GeoLocation_Latitude = 0;
export let GeoLocation_Longitude = 0;
export const $ = selector => document.querySelector(selector);
export const $$ = selector => document.querySelectorAll(selector);
export const $attr = (selector, name, value) => document.querySelector(selector).setAttribute(name, value);
export const $html = (selector, html) => document.querySelector(selector).innerHTML = html;
export const add_meta = (description) => {
  const meta = document.createElement('meta');
  meta.name = 'description';
  meta.content = description;
  document.head.appendChild(meta);
};
export const add_link = (rel, href, hreflang = '') => {
  const link = document.createElement('link');
  link.rel = rel;
  link.href = href;
  hreflang && (link.hreflang = hreflang);
  document.head.appendChild(link);
};
export const set_title = title => document.title = title;

export function GetPosition(igonreAlert) {
  if (GeoLocation_Latitude !== 0 && GeoLocation_Longitude !== 0) {
    return;
  }
  return new Promise(function (resolve) {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const coordinates = {
      latitude: null,
      longitude: null,
      error: false,
      accuracy: '',
    };
    function success(pos) {
      coordinates.latitude = pos.coords.latitude;
      coordinates.longitude = pos.coords.longitude;
      coordinates.accuracy = pos.coords.accuracy;
      resolve(coordinates);
    }

    function fail() {
      coordinates.error = true;
      if (igonreAlert) {
        //app.run('show-message', 'WARNING: The appraiser may require you to retake photos as you have disabled location services', 'info');
      }
      resolve(coordinates); // or reject(error);
    }

    navigator.geolocation.getCurrentPosition(success, fail, options);
  });
}

export async function getGeoLocation() {
  const position = await GetPosition(true); // wait for getPosition to complete
  if (position && position['latitude'] && position['longitude']) {
    GeoLocation_Latitude = position['latitude'];
    GeoLocation_Longitude = position['longitude'];
  } else {
    GeoLocation_Latitude = RoyalLePage_Latitude;
    GeoLocation_Longitude = RoyalLePage_Longitude;
  }
  return { GeoLocation_Latitude, GeoLocation_Longitude };
}

export function distance(lat1, lon1, lat2, lon2, unit = 'K') {
  if ((lat1 === lat2) && (lon1 === lon2)) {
    return 0;
  } else {
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') { dist = dist * 1.609344; }
    if (unit === 'N') { dist = dist * 0.8684; }
    return dist;
  }
}

export const PageSize = 10;

export function paginate(array, page_size, page_number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export function paginateAssets(array, page_number) {

  return paginate(array, PageSize, page_number);
}

export function uniqByKeepLast(data, key) {
  return [
    ...new Map(data.map(x => [key(x), x])).values()
  ];
}

export const setSearchResultValues = function (keyword, name, sortBy) {

  if (keyword) {
    const e = document.getElementsByName('keyword')[0] as HTMLInputElement;
    e.value = keyword;
  }

  if (name) {
    const e = document.getElementsByName('name')[0] as HTMLInputElement;
    e.value = name;
  }

  const e = $('#ajax_sort_properties');
  for (let i = 0; i < e.getElementsByTagName('option').length; i++) {
    e.getElementsByTagName('option')[i].selected = e.getElementsByTagName('option')[i].value === sortBy;
  }
};

export const sortAgentsOrAgencies = function (ags, sortBy) {
  switch (sortBy) {
    case 'a_name': {
      ags.sort((a1, a2) => {
        return a2.name.toLowerCase() > a1.name.toLowerCase() ? -1 : 1;
      });
      break;
    }
    case 'd_name': {
      ags.sort((a1, a2) => {
        return a1.name.toLowerCase() > a2.name.toLowerCase() ? -1 : 1;
      });
      break;
    }

    default: {
      ags.sort((a1, a2) => a1.distance - a2.distance);
      break;
    }

  }
  return ags;
};

export const moveTo = function (tagId) {
  const element = $(tagId);
  element.scrollIntoView({ behavior: 'smooth' });
};

export const getId = function () {
  const params = new URLSearchParams(location.search);
  let id = params.get('id');

  if (!id) {
    const ps = location.pathname.split('/');
    if (ps.length > 2) {
      if (ps[1] === 'fr' || ps[1] === 'en') {
        id = ps[3];
      } else {
        id = ps[2];
      }
    }
  }
  return id;
};

export const getPropertyLink = function (property, language = null) {
  if (!language) { language = lang.get(); }
  const link = fix_link(`${property.address}-${property.city}-${property.province}-${property.postal_code}`);
  return (language === 'fr') ? `/fr/property/${property.id}/${link}` : `/property/${property.id}/${link}`;
};

export const getPropertyLinkWithoutAddress = function (property, language = null) {
  if (!language) { language = lang.get(); }
  return (language === 'fr') ? `/fr/property/${property.id}` : `/property/${property.id}`;
};

export const getAgentLink = function (agent, language = null) {

  if (!language) { language = lang.get(); }
  const link = fix_link(`${agent.name}-${agent.address}-${agent.city}-${agent.province}-${agent.postal_code}`);
  return (language === 'fr') ? `/fr/agent/${agent.id}/${link}` : `/agent/${agent.id}/${link}`;
};

export const getAgencyLink = function (agency, language = null) {
  if (!language) { language = lang.get(); }
  const link = fix_link(`${agency.name}-${agency.address}-${agency.city}-${agency.province}-${agency.postal_code}`);
  return (language === 'fr') ? `/fr/office/${agency.id}/${link}` : `/office/${agency.id}/${link}`;
};

function fix_link(link: string) {
  return link.replace(/[\s/]/g, '-').replace(/#/g, '').replace(/-+/g, '-').toLowerCase();
}

export function build_share_links(property) {
  ($('.houzez-icon.icon-messaging-whatsapp.mr-1').parentElement as HTMLAnchorElement).href = `https://api.whatsapp.com/send?text=${__('Detail')}&nbsp;${window.location.href}`;
  ($('.houzez-icon.icon-social-media-facebook.mr-1').parentElement as HTMLAnchorElement).href = `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(window.location.href)}`;
  ($('.houzez-icon.icon-social-media-twitter.mr-1').parentElement as HTMLAnchorElement).href = `https://twitter.com/intent/tweet?text=${__('Detail')}&amp;url=${encodeURI(window.location.href)}`;
  ($('.houzez-icon.icon-social-pinterest.mr-1').parentElement as HTMLAnchorElement).href = `https://pinterest.com/pin/create/button/?url=${encodeURI(window.location.href)}&amp;media=${encodeURI(window.location.href)}`;
  ($('.houzez-icon.icon-professional-network-linkedin.mr-1').parentElement as HTMLAnchorElement).href = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(window.location.href)}`;

  const specificAnchor = document.querySelector('.houzez-icon.icon-professional-network-linkedin.mr-1').parentElement as HTMLAnchorElement;
  const siblingAnchor = specificAnchor.nextElementSibling;
  if (!siblingAnchor || !siblingAnchor.classList.contains('dropdown-item')) {
    const newAnchor = document.createElement('a');

    const subject = `MLS ${property.mls_id} - ${property.address}`;
    newAnchor.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURI(window.location.href)}`;
    newAnchor.innerHTML = `<i class="houzez-icon icon-envelope mr-1"></i>${__('Email')}`;
    newAnchor.classList.add('dropdown-item');
    specificAnchor.parentNode.insertBefore(newAnchor, specificAnchor.nextElementSibling);
  }

}

export const showLoading = (selector) => {
  $html(selector, `<div class="loader-ripple spinner" style="margin:10px;">
    <div class="bounce1" ></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>`);
};

export const setDetailLanguageLink = function (obj, linkFunction) {
  const anchors = $$('.lang-item');

  for (let i = 0; i < anchors.length; ++i) {
    const anchor = anchors[i].firstChild as HTMLAnchorElement;
    anchor.href = (lang.get() === 'fr' ? linkFunction(obj, 'en') : linkFunction(obj, 'fr'));
  }
  return;
};

export const getUrl = function (url) {
  url = url.trim().toLowerCase();
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  } else {
    return `http://${url}`;
  }
};

export const getAgencyLinkById = function (agencyId, language = null) {
  if (!language) { language = lang.get(); }
  return (language === 'fr') ? `/fr/office/${agencyId}` : `/office/${agencyId}`;
};

export const getTrimUrl = function (url) {
  url = url.trim().toLowerCase();
  url = url.replace('http://www.', '').replace('https://www.', '').replace('http://', '').replace('https://', '');
  return url;
};

export const getProvinceInfo = function (provinceStr) {
  const provinces = {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NS: 'Nova Scotia',
    NT: 'Northwest Territories',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon'
  };

  // Check if the input is already a 2-letter province code
  if (provinceStr.toUpperCase() in provinces) {
    const code = provinceStr.toUpperCase();
    const name = provinces[code];
    return [code, name];
  }

  // Check if the input is a full province name (English only)
  for (const code in provinces) {
    const name = provinces[code];
    if (provinceStr.toLowerCase() === name.toLowerCase()) {
      return [code, name];
    }
  }

  // If the input is not a valid province code or name, return the input parameter as the province name
  return [provinceStr.toUpperCase(), provinceStr];
};

export const hasPropertyType = function (propertyType, propertyTypeStr) {
  return propertyType ? (propertyType.toLowerCase().indexOf(propertyTypeStr) >= 0) : false;
};

export const hasExactPropertyType = function (propertyType, propertyTypeStr) {
  return propertyType ? (propertyType.toLowerCase() === propertyTypeStr) : false;
};
export const getPropertyIcon = function (property_type) {
  if (!property_type) { return '/wp-content/themes/houzez/img/map/pin-none.png'; }

  const propertyType = property_type[0] ? property_type[0].toLowerCase() : '';

  let type;
  if (hasPropertyType(propertyType, 'office')) {
    type = 'office';
  } else if (hasPropertyType(propertyType, 'industrial')) {
    type = 'industrial';
  } else if (hasPropertyType(propertyType, 'multi-family') || hasPropertyType(propertyType, 'multifamily')
    || hasPropertyType(propertyType, 'condo') || hasPropertyType(propertyType, 'unit')
    || hasPropertyType(propertyType, 'duplex') || hasPropertyType(propertyType, 'triplex')
    || hasPropertyType(propertyType, 'fourplex') || hasPropertyType(propertyType, 'quintuplex')
    || hasPropertyType(propertyType, 'two or more storey')) {
    type = 'multi-family';
  } else if (hasPropertyType(propertyType, 'retail')) {
    type = 'retail';
  } else if (

    hasExactPropertyType(propertyType, 'lot') || hasExactPropertyType(propertyType, 'part woods')
    || hasExactPropertyType(propertyType, 'part bush') || hasExactPropertyType(propertyType, 'part cleared')
    || hasExactPropertyType(propertyType, 'part workable')
    || hasPropertyType(propertyType, 'mobile/mini') || hasPropertyType(propertyType, 'no building onsite')
    || hasPropertyType(propertyType, 'vacant') || hasPropertyType(propertyType, 'acreage')

  ) {
    type = 'land';
  } else if (hasPropertyType(propertyType, 'business')) {
    type = 'business';
  } else if (hasPropertyType(propertyType, 'agriculture') || hasPropertyType(propertyType, 'farm')) {
    type = 'agriculture';
  } else if (hasPropertyType(propertyType, 'hospitality')) {
    type = 'hotel-motel';
  } else if (hasPropertyType(propertyType, 'investment') || hasPropertyType(propertyType, 'institutional')) {
    type = 'investment';
  } else if (
    hasExactPropertyType(propertyType, 'commercial') || hasExactPropertyType(propertyType, 'res')
    || hasPropertyType(propertyType, 'other')
    || hasPropertyType(propertyType, 'bulk') || hasPropertyType(propertyType, 'residential')
    || hasPropertyType(propertyType, 'detached') || hasPropertyType(propertyType, 'parking')
    || hasPropertyType(propertyType, 'unknown') || hasPropertyType(propertyType, 'year round access')
  ) {
    type = 'other';
  } else {
    type = 'none';
  }

  return '/wp-content/themes/houzez/img/map/pin-' + type + '.png';
};

export const getEllipsisString = function (str, len) {
  return str.length > len ? str.slice(0, len) + '...' : str;
};

export const hasSameItem = function (string1, string2) {
  const items1 = string1.toLowerCase().split(',');
  const items2 = string2.toLowerCase().split(',');

  for (let i = 0; i < items1.length; i++) {
    if (items2.indexOf(items1[i].trim()) !== -1) {
      return true;
    }
  }

  return false;
};

export const checkMapList = function () {
  const params = new URLSearchParams(location.search);
  const map_view = params.get('map_view') ? params.get('map_view').trim() : '2';
  if (window.innerWidth < 768) {
    if (map_view === '1') {
      ($('#half-map-listing-area, .listing-wrap') as HTMLInputElement).style.display = 'none';
      ($('#map-view-wrap') as HTMLInputElement).style.display = 'block';
    } else if (map_view === '2') {
      ($('#half-map-listing-area, .listing-wrap') as HTMLInputElement).style.display = 'block';
      ($('#map-view-wrap') as HTMLInputElement).style.display = 'none';
    }
  } else {
    ($('#half-map-listing-area, .listing-wrap') as HTMLInputElement).style.display = 'block';
    ($('#map-view-wrap') as HTMLInputElement).style.display = 'block';
  }
};

export const checkNumber = function (str) {
  if (!isNaN(str)) {
    return parseInt(str, 10);
  } else {
    return str;
  }
};

export const formatterCurrency = function (num) {
  const formatter = new Intl.NumberFormat(lang.get() === 'fr' ? 'fr-CA' : 'en-CA', {
    style: 'currency',
    currency: 'CAD',
    maximumFractionDigits: 0
  });

  return formatter.format(Math.round(num));
};

export function checkValueInArray(array, field, value) {
  return array.some(item => item[field] && item[field].toString() === value.toString());
}